













.input-group {
	margin-bottom: var(--spacing-base);
	margin-top: var(--spacing-base);
}

.input-label {
	font-size: var(--typo-size-micro);
	font-weight: normal;
	margin-bottom: var(--spacing-mini);
}
