



























.list-flexy {
	display: flex;
	flex-wrap: wrap;
	margin: calc(var(--list-spacing-v, var(--spacing-base)) * -1) calc(var(--list-spacing-h, var(--spacing-base)) * -1);

	> li {
		margin: var(--list-spacing-v, var(--spacing-base)) var(--list-spacing-h, var(--spacing-base));
	}
}
