




































































.gameboard-player {
	align-items: center;
	border: 4px solid transparent;
	display: flex;
	font-size: var(--typo-spacing-base);
	padding: var(--spacing-micro) var(--spacing-mini);
	position: relative;

	@media (min-width: 500px) {
		padding: var(--spacing-micro) var(--spacing-base);
	}

	&__image {
		display: flex;
		margin-right: var(--spacing-mini);
	}

	&__main {

	}

	&__avatar {
		font-size: 1.5rem;
	}

	&__name {
		font-size: 1em;
		margin: 0;
		white-space: nowrap;
	}

	&--active {
		border-color: var(--primary);
		transform: scale(1.01);
	}
}
