










































.gameboard-players {
	--list-spacing-v: var(--spacing-micro);
	--list-spacing-h: var(--list-spacing-v);

	> li {
		@media (orientation: landscape) {
			flex: 1 1 100%;
		}
	}

	@media (min-width: 600px) {
		--list-spacing-v: var(--spacing-mini);
	}
}
