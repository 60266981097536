


































































.timer {
	align-items: center;
	display: inline-flex;
	min-width: 10ch;

	> * {
		margin-right: 0.25em;

		&:last-child {
			margin-right: 0;
		}
	}

	&__digit-unit {
		display: inline-flex;
	}

	&__digit {

	}

	&__unit {

	}

	&__icon {
		font-size: 1.125em;
	}
}
