









































.results-player {
	text-align: center;

	&__avatar {
		display: inline-flex;
		font-size: 4rem;
		margin-bottom: var(--spacing-mini);
	}

	&__hdln {
		font-size: var(--typo-size-macro);
		margin: var(--spacing-mini) 0 0;
	}

	&__body {
		margin-top: var(--spacing-mini);

		strong {
			color: var(--primary);
			font-size: 1.5em;
			font-weight: bold;
		}
	}

	&__cards {
		margin-top: var(--spacing-base);
	}
}
