











































































































































































.gameboard-cards {
	--gutter: var(--spacing-micro);
	--width: 1;

	align-items: center;
	justify-content: center;
	margin: 0;
	max-height: 100%;
	width: 100%;

	> li {
		flex: 0 1 calc(var(--width) * 100%);
		margin: 0;
		padding: Min(2vw, var(--gutter));
		transition: opacity 250ms;
		will-change: opacity;
	}

	@media (min-width: 600px) {
		--gutter: var(--spacing-mini);
	}

	@media (min-width: 900px) {
		--gutter: var(--spacing-base);
	}

	&--reloading {
		> li {
			opacity: 0;
		}
	}
}
