









































.results-victorius {
	&__hdln {
		font-size: 4rem;
		margin: 0 0 var(--spacing-mega);
		text-align: center;
	}
}
