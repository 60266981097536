





























































































































































.player-creator {
	display: flex;
	flex-wrap: wrap;
	padding: var(--spacing-base);

	&__image {
		display: flex;
		margin-right: var(--spacing-base);
		position: relative;
	}

	&__main {
		flex: 1 1 auto;
	}

	&__avatar {
		font-size: 1.75em;
	}

	&__actions {
		text-align: right;
		width: 100%;
	}

	.input-group {
		margin: 0;
		width: 100%;
	}
}

.avatar {

	&__btn {
		background: var(--surface);
		border: 2px solid var(--primary);
		border-radius: 9em;
		bottom: -1rem;
		box-shadow: 0 0 0px 3px var(--surface);
		font-size: 1rem;
		padding: 3px;
		position: absolute;

		&--color {
			left: -0.5rem;
		}

		&--symbol {
			right: -0.5rem;
		}
	}
}
