

































.temporal {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	&__time {
		margin-right: var(--spacing-base);
	}

	&__rounds {
	}
}
