



























.results-players-list {
	align-items: stretch;
	justify-content: center;

	> li {
		flex: 1 1 auto;
	}
}
