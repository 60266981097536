










.rich-text {
	max-width: 75ch;

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}
