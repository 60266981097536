



























































.gameboard-actions {
	--list-spacing-v: var(--spacing-micro);
	--list-spacing-h: var(--list-spacing-v);

	justify-content: flex-end;

	@media (orientation: landscape) {
		justify-content: center;
	}

	@media (min-width: 30rem) {
		--list-spacing-v: var(--spacing-mini);
	}

	> li {
		flex: 0 1 auto;
	}

	&__btn {
		padding: 0.5rem;
	}
}
