


















.compound-input {
	display: flex;
	padding: 0;

	&__input {
		flex: 1 1 auto;
	}

	&__prefix,
	&__suffix {
		flex: 0 0 auto;
		padding: 0.25em 0.5em;

		&:disabled {
			opacity: 0.4;
		}
	}


	&--inline {
		display: inline-flex;
		width: auto;
	}
}
