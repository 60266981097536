




















































































.results-screen {
	background: var(--surface);
	left: 0;
	min-height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;

	&__section {
		margin: var(--spacing-macro) auto;
	}

	&__btn-list {
		text-align: center;
	}
}
