
































.results-player-victorius {
	&__star-crown {
		display: flex;
		justify-content: center;
		margin-bottom: var(--spacing-mini);
	}
}
