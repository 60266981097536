








































.results-non-victorius {
	&__hdln {
		font-size: var(--typo-size-macro);
		margin: 0 0 var(--spacing-mega);
		text-align: center;
	}
}
