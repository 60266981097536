







































.card-image {
	padding-bottom: 100%;
	position: relative;
	width: 100%;

	&__image {
		height: 100%;
		left: 0;
		object-fit: contain;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
