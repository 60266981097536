











































































@import "~scss-mixins-functions-variables/scss/layout/visually-hidden-mixins";

.game-theme-list {
	display: grid;
	grid-auto-rows: 1fr;
	grid-gap: var(--spacing-base) var(--spacing-base);
	grid-template-columns: repeat(auto-fill, minmax(clamp(15% #{"-"} var(--spacing-base), 70px, 100%), 1fr));
	margin: 0;

	&__input {
		@include visually-hidden();
	}

	&__label {
		display: block;
	}

	.card {
		--on-surface: var(--primary);
	}
}
