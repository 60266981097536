












.btn-list {
	margin: calc(var(--spacing-mini) #{"*"} -1) calc(var(--spacing-base) #{"*"} -1);

	> * {
		margin: var(--spacing-mini) var(--spacing-base);
	}
}
