





























































































































































































@import "~scss-mixins-functions-variables/scss/layout/visually-hidden-mixins";
@import "~scss-mixins-functions-variables/scss/typography/font-weight-variables";

.avatar {
	align-items: center;
	background: var(--surface);
	border-radius: 9em;
	color: var(--on-surface);
	display: flex;
	font-size: 3rem;
	font-weight: $typography-weight-normal;
	justify-content: center;
	height: 2em;
	width: 2em;

	figcaption {
		@include visually-hidden();
	}

	&__icon {
		font-size: 1em;
	}
}
