











































































































































.number-input {
	appearance: textfield;
	text-align: center;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
