
















































.result-cards {
	--list-spacing-h: var(--spacing-mini);
	--list-spacing-v: var(--list-spacing-h);

	justify-content: center;
	margin: 0;

	> li {
		height: 50px;
		position: relative;
		width: 50px;
	}

	&__card {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transform: rotate(var(--rotation));
	}
}
